import React, { useEffect, useState } from 'react'
import { PODCASTER_FORM_INPUT_TYPES, SLIDER_TYPES, SONAR_MUSE_NFT_STORAGE_KEY, TRANSACTION_OPTIONS } from '../../../utils/Enum'
import ContentWrapper from '../../ContentWrapper'
import { Slider } from '../../Slider'
import { newlineFormatter, stringFormatter, validateAddress } from '../../../utils'
import { FormButton1, FormInput1, FormLabel, FormText, FormTextArea, FormWrapper } from '../../CustomForm/CustomFormElements'
import XRPL from '../../XRPL'
import { ActionButton, ActionButtonsContainer } from '../PodcasterProfileHeader/PodcasterProfileHeaderElements'
import ToolTip from '../../ToolTip'
import { FaInfo } from 'react-icons/fa'
import { ErrorText } from '../../Profile/ProfileContent/ProfileContentElements'
import ToggleButton from '../../ToggleButton'
import { PushSpinner } from 'react-spinners-kit'
import AlbumArt from '../../AlbumArt'
import { HoverOption } from '../../MenuOptions/MenuOptionsElements'
import OptionModal from '../../OptionModal'
import QRCodeForm from '../../QRCodeForm'
import Modal from '../../Modal'
import { Theme } from '../../WebsiteThemeElements'
import { PodcasterContentHeading, PodcasterContentHeadingWrapper, PodcasterContentWrapper, PodcasterSettingsWrapper } from './PodcasterProfileContentElements'
import { useModal } from '../../Modal/ModalContext'
import usePodcaster from '../../../hooks/usePodcaster'
import { getDownloadUrl, storage, storageRef, uploadBytes } from '../../Authorization/firebase'
import { DEFAULT_IMAGE, getUserProfile, hashEmail } from '../../GravatarAPI'
import CurrencySelector from '../../CurrencySelector'
import { BioInput } from '../../ArtistProfile/ArtistContent/ArtistContentElements'
import { Button1, CustomButton, CustomButton1 } from '../../ButtonElement'
import { MdRefresh } from 'react-icons/md'
import MicropaymentsFundsClaimer, { MicropaymentFundsDisable } from '../../MicropaymentsFundsClaimer'
import { useNavigate } from 'react-router-dom'
import { Nav, NavContainer, NavLink } from '../../NavLink/NavLinkElements'
import GravatarSettings from '../../GravatarAPI/GravatarSettings'
import axiosInstance from '../../Authorization/client'
import { getPodcasterPendingMicropayments } from '../../../utils/dbUtils/podcasterUtils'
import { useWebsiteTheme } from '../../WebsiteTheme/WebsiteThemeContext'

const PodcasterSettings = ({podcaster, podcasterId, getProfile, podcasterSettings, gravatarProfile, about}) => {
  const ppRegex = /^[\x36]{1}.ilp.\w{5,}.\w|\d{5,}$/;
  const {themeColors}= useWebsiteTheme()
  const { toggleModal, modalContent, closeModal, openModal, noCloseModal } = useModal();
  const navigate  = useNavigate()
  const { getPodcasterFunds } = usePodcaster({});
  
  const storageToken = SONAR_MUSE_NFT_STORAGE_KEY;
  const [currency, setCurrency] = useState(
    podcaster?.currency ? podcaster.currency : "XRP"
  );
  const [paymentChannel, setPaymentChannel] = useState(
    podcaster?.paymentChannel?.channel ? podcaster?.paymentChannel : undefined
  );
  const [funds, setFunds] = useState(podcaster?.funds ? podcaster?.funds : undefined); //useEffect to get funds in real time
  const [fundsCurrency, setFundsCurrency] = useState("XRP")
  const [curencyRate, setCurrencyRate] = useState(0)
  const [isRateLoaded, setRateLoaded] = useState(0)
  const [podcasterName, setName] = useState(podcaster?.name);
  const [fullName, setFullName] = useState(podcaster?.fullName);
  const [email, setEmail] = useState(podcaster?.email);
  const [podcasterProfile, setProfile] = useState({});
  const [hasGravatarProfile, setHasGravatar] = useState(false)
  const [requestUpdate, setRequestUpdate] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const [tipping, setTipping] = useState(podcasterSettings?.tipping ? podcasterSettings?.tipping : false)
  const [errors, setErrors] = useState({});
  const [subscriptionFee, setFee] = useState(
    podcaster?.subscriptionFee ? podcaster.subscriptionFee : 0
  );
  const [showGallery, setShowGallery] = useState(
    podcasterSettings?.showGallery && podcasterSettings?.showGallery
  );
  const [showTrips, setTrips] = useState(podcasterSettings?.showTrips);
  const [headerType, setHeaderType] = useState(
    gravatarProfile?.podcasterBackground
      ? podcasterSettings?.headerType
        ? podcasterSettings.headerType
        : gravatarProfile.podcasterBackground.url
        ? "background"
        : "color"
      : false
  );
  const [bioType, setBioType] = useState(
    about?.bio
      ? podcasterSettings?.bioType
        ? podcasterSettings.bioType
        : "gravatar"
      : "custom"
  );
  const [profilePictureType, setProfilePictureType] = useState(
    podcasterSettings?.profilePictureType
      ? podcasterSettings?.profilePictureType
      : "gravatar"
  );

  const [bio, setBio] = useState(
    podcasterSettings?.bioType
      ? podcasterSettings?.bioType === "custom"
        ? podcaster?.bio
        : about?.bio
      : about?.bio
  );

  const [profilePicture, setProfilePicture] = useState(
    podcasterSettings?.profilePictureType
      ? podcasterSettings?.profilePictureType === "custom"
        ? podcaster.profilePicture
        : {}
      : {}
  );
  const [paymentPointer, setPaymentPointer] = useState(podcaster?.paymentPointer);
  const [walletAddress, setWalletAddress] = useState({ address: podcaster?.uid });
  const [devnetAddress, setDevnetAddress] = useState(
    podcaster?.devnetAddress ? podcaster?.devnetAddress : ""
  );
  const [nftStorageApiKey, setNftStorageApiKey] = useState(
    podcaster?.nftStorageAPIKey ? podcaster?.nftStorageAPIKey : ""
  );
  const toggleStyle = {
    display: "flex",
    color: "white",
  };
  const [currentTab, setTab] = useState('general')
  const [apiKeyType, setApiKeyType] = useState(
    podcaster?.nftStorageAPIKey === storageToken ? true : false
  );



  useEffect(() => {
    if(podcaster?.email?.length > 0){
      getUserProfile(hashEmail(podcaster?.email)).then((profile) => {
        if(profile){
          setHasGravatar(true)
          return
        }
        setHasGravatar(false)
        return
      }).catch(err => {
        setHasGravatar(false)
      })
    }
         
      return () => {}
    }, [podcaster?.email]);

  useEffect(() => {
    if( fundsCurrency !== "XRP" ){
      if(fundsCurrency === "WYM"){
        setCurrencyRate("XRP")
        return
      }
      setRateLoaded(false)
      axiosInstance.get(`/api/${fundsCurrency}/getPrice`).then((rate) => {
        if(rate?.data?.price){
          setCurrencyRate(rate.data?.price)
          setRateLoaded(true)
          return
        }
        setCurrencyRate("XRP")
        setRateLoaded(true)
      }).catch(err => {
        setCurrencyRate("XRP")
        setRateLoaded(true)
      })
    }
  }, [fundsCurrency])

  useEffect(() => {
    getFunds()
    return () => {}
  }, [podcasterId])

  const getFunds = async () => {
    getPodcasterPendingMicropayments(podcasterId, setFunds)
    /* if(podcasterId && paymentChannel?.channel){
    const client = new Client(SERVERS.MAINNET[0]);
    let channels = [];
    await client.connect();
    try {
      let resp = await client.request({
        id: 1,
        command: "account_channels",
        account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
        destination_account: podcaster?.uid,
        ledger_index: "validated",
      });
  
      // Create a function to run on each API call.
      function printLedgerResult() {
        //console.log(resp["result"]);
      }
      const ledger_data_index = resp["result"]["ledger_index"];
      // Execute function at least once before checking for markers.
      do {
        //printLedgerResult();
  
        if (resp["result"]["marker"] === undefined) {
          break;
        }
  
        // Specify the same ledger and add the marker to continue querying.
        const ledger_marker = await client.request({
          command: "account_channels",
          account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
          destination_account: podcaster?.uid,
          ledger_index: ledger_data_index,
          marker: resp["result"]["marker"],
          ledger_index: "validated",
        });
        channels.push(...ledger_marker?.result?.channels);
        resp = ledger_marker;
      } while (true);
      client.disconnect();
      if (channels?.length > 0) {
        let c = channels.filter((c) => c?.channel_id === paymentChannel?.channel)[0];
        setFunds(c?.amount - c?.balance)
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  } */
  };

  const enableMonetization = () => {
    openModal();
    modalContent(
      <>
        <OptionModal
          heading={"Enabling Web Monetization"}
          additionalText={
            "Note: To enable web monetization, you will need to sign a transaction with a fee of 0.1 XRP to fund your account"
          }
        >
          <HoverOption
            onClick={() =>
              noCloseModal(
                <QRCodeForm
                  heading={"Enabling Web Monetization"}
                  body={{
                    memo: `Podcaster ${podcasterName} is signing transaction to enable Web Monetization`,
                    instruction: `Podcaster ${podcasterName} is signing transaction to enable Web Monetization`,
                    sender: podcaster?.uid,
                    podcasterId: podcasterId,
                    type: "podcaster"
                  }}
                  type={TRANSACTION_OPTIONS.ENABLE_WEB_MONETIZATION}
                  onSuccess={onMonetizationEnableSuccess}
                  onFail={onMonetizationEnableFail}
                  cancelQr={onMonetizationEnableCancel}
                />
              )
            }
          >
            Enable Web Monetization
          </HoverOption>
          <HoverOption>Cancel</HoverOption>
        </OptionModal>
      </>
    );
  };

  const onMonetizationEnableSuccess = (data) => {
    closeModal();
    modalContent();
    setPaymentChannel(data.paymentChannel);
    setFunds(data.funds);
  };

  const onMonetizationEnableFail = () => {
    closeModal();
    modalContent();
  };

  const onMonetizationEnableCancel = () => {
    closeModal();
    modalContent();
  };

  const claimFunds = () => {
    openModal();
    modalContent(
      <MicropaymentsFundsClaimer
        funds={funds}
        paymentChannel={paymentChannel}
        name={podcaster?.uid}
        id={podcasterId}
        type={"Podcaster"}
        onSuccess={() => {
          closeModal();
          modalContent();
        }}
      />
    );
  };

  const disableFunds = () => {
    openModal();
    modalContent(
      <MicropaymentFundsDisable
        funds={funds}
        paymentChannel={paymentChannel}
        name={podcaster?.uid}
        id={podcasterId}
        type={"Artist"}
        onSuccess={() => {
          closeModal();
          setPaymentChannel()
          modalContent();
        }}
      />
    );
  };

  const switchProfilePictureType = () => {
    if (profilePictureType === "gravatar") {
      setProfilePictureType("custom");
    } else {
      setProfilePictureType("gravatar");
    }
  };

  const switchBioType = () => {
    if (bioType === "gravatar") {
      setBioType("custom");
    } else {
      setBioType("gravatar");
    }
  };

  const switchHeaderType = () => {
    if (headerType === "color") {
      setHeaderType("background");
    } else {
      setHeaderType("color");
    }
  };

  const toggleTipping = () => {
    setTipping(!tipping)
  }

  const handleInput = (e) => {
    let cErrors = errors;
    if (e.target.value.trim() !== "") {
      switch (e.target.name) {
        case PODCASTER_FORM_INPUT_TYPES.NAME:
          delete cErrors?.name;
          setName(e.target.value);
          break;
        case PODCASTER_FORM_INPUT_TYPES.FULL_NAME:
          delete cErrors?.fullName;
          setFullName(e.target.value);
          break;
        case PODCASTER_FORM_INPUT_TYPES.EMAIL:
          delete cErrors?.email;
          setEmail(e.target.value);
          break;
        case PODCASTER_FORM_INPUT_TYPES.ADDRESS:
          delete cErrors?.address;
          setWalletAddress({ address: e.target.value });
          break;
        case PODCASTER_FORM_INPUT_TYPES.SUBSCRIPTION_FEE:
          setFee(parseInt(e.target.value));
          delete cErrors?.fee;
          break;
        case PODCASTER_FORM_INPUT_TYPES.BIO:
          setBio(newlineFormatter(e.target.value));
          break;
        case PODCASTER_FORM_INPUT_TYPES.PAYMENT_POINTER:
          setPaymentPointer(e.target.value);
          delete cErrors?.paymentPointer;
          break;
        case PODCASTER_FORM_INPUT_TYPES.NFT_STORAGE_API_KEY:
          setNftStorageApiKey(e.target.value);
          delete cErrors?.nftStorageApiKey;
          break;
        case PODCASTER_FORM_INPUT_TYPES.PROFILE_PICTURE:
          let currArt = profilePicture;
          if (e.target.value && e.target.files[0]) {
            toggleModal();
            modalContent();
            var reader = new FileReader();

            //Read the contents of Image File.
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (e1) {
              //Initiate the JavaScript Image object.
              var image = new Image();

              //Set the Base64 string return from FileReader as source.
              image.src = e1.target.result;

              setProfilePicture({ file: e.target.files[0] });
              image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height < 1600 || width < 1600) {
                  alert("Height and Width must not be less than 1600px.");
                  setProfilePicture();
                  return false;
                }

                //let artBlob = convertFileToBytes(e.target.files[0])
                let imageFileName;
                if (!profilePicture?.name) {
                  imageFileName = `ProfilePicture.${
                    e.target.files[0].name.split(".")[
                      e.target.files[0].name.split(".").length - 1
                    ]
                  }`;
                  setProfilePicture((prev) => {
                    return { ...prev, name: imageFileName };
                  });
                } else {
                  imageFileName = profilePicture?.name;
                }
                const uploadTask = uploadBytes(
                  storageRef(storage, `podcasters/${podcasterId}/${imageFileName}`),
                  e.target.files[0],
                  { contentType: e.target.files[0].type }
                );

                uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProfilePicture((prev) => {
                      return { ...prev, progress: progress };
                    });
                    /* if(isCancelSongUpload){
                      if (!uploadTask.isComplete()) {
                        //Upload is not complete yet, let's cancel
                        uploadTask.cancel();
                    } else {
                        //Upload is complete, but user wanted to cancel. Let's delete the file
                        uploadTask.snapshot.ref.delete();
                        // storageRef.delete(); // will delete all your files
                    }
                    } */
                    //console.log("Upload is " + progress + "% done");
                    switch (snapshot.state) {
                      case "paused":
                        break;
                      case "running":
                        /* if(isCancelSongUpload){
                          if (!uploadTask.isComplete()) {
                            //Upload is not complete yet, let's cancel
                            uploadTask.cancel();
                        } else {
                            //Upload is complete, but user wanted to cancel. Let's delete the file
                            uploadTask.snapshot.ref.delete();
                            // storageRef.delete(); // will delete all your files
                        }
                        } */
                        break;
                      default:
                        /*  if(isCancelSongUpload){
                          if (!uploadTask.isComplete()) {
                            //Upload is not complete yet, let's cancel
                            uploadTask.cancel();
                        } else {
                            //Upload is complete, but user wanted to cancel. Let's delete the file
                            uploadTask.snapshot.ref.delete();
                            // storageRef.delete(); // will delete all your files
                        }
                        } */
                        //console.log("Upload is " + progress + "% done");
                        break;
                    }
                  },
                  (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                      case "storage/unauthorized":
                        // User doesn't have permission to access the object
                        break;
                      case "storage/canceled":
                        setProfilePicture(currArt);
                        // User canceled the upload
                        break;
                      // ...
                      case "storage/unknown":
                        setProfilePicture(currArt);
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                      default:
                        break;
                    }
                  },
                  () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadUrl(uploadTask.snapshot.ref).then(
                      (downloadURL) => {
                        setProfilePicture((prev) => {
                
                          return {
                            ...prev,
                            url: downloadURL,
                            file: e.target.files[0],
                            ref: uploadTask.snapshot.ref.fullPath,
                          };
                        });
                        //console.log("File available at", downloadURL);
                      }
                    );
                  }
                );
              };
            };
          }
          break;
        default:
          break;
      }
      setErrors(cErrors);
    }
  };

  const validatePaymentPointer = (pp) => {
    return ppRegex.test(pp);
  };

  const onSuccess = async () => {
    setRequestUpdate(false);
    setUpdated(true);
    setErrors({ message: "Profile successfully updated" });
    await getProfile();
    navigate(`/podcaster/${podcasterId}`)
  };

  const onFailure = () => {
    setRequestUpdate(false);
    setUpdated(false);
    setErrors({ message: "Profile failed to update" });
  };

  const cancelUpload = () => {
    setRequestUpdate(false);
    setUpdated(false);
  };


  const validateInfo = () => {
    let cErrors = {};
    if (
      (podcasterName.length > 0 && podcasterName.trim() === "") ||
      podcasterName === ""
    ) {
      cErrors.name = "Artist Name cannot be empty";
    }
    if (fullName?.length > 0 && fullName?.trim() === "") {
      setFullName(podcasterName);
    }
    if (
      walletAddress.address.length > 0 &&
      validateAddress(walletAddress.address)
    ) {
      if (!walletAddress?.confirmed && walletAddress.address !== podcaster.uid) {
        cErrors.address = "You have not confirmed changing address";
      }
      if (walletAddress.address.trim() === "") {
        setWalletAddress({ address: podcaster.uid });
      }
    }
    if (walletAddress.address.trim() === "") {
      setWalletAddress({ address: podcaster.uid });
    }

    if (bioType === "custom" && (bio === about.bio || bio.trim() === "")) {
      setBio();
    }
    if (Object.keys(cErrors).length === 0) {
      return true;
    } else if (Object.keys(cErrors).length > 0) {
      setErrors((prev) => {
        return { ...prev, ...cErrors };
      });
      return false;
    }
  };

  const updateProfile = () => {
    if (validateInfo() === true) {
      setErrors({});
      let profile = {
        podcasterSettings: {
          bioType,
          headerType,
          showTrips,
          showGallery,
          profilePictureType,
          tipping: tipping,
        },
        bio: bioType === "custom" && bio,
        name: podcasterName,
        fullName: fullName,
        profilePicture: profilePicture?.url
          ? {
              url: profilePicture?.url,
              ref: profilePicture?.ref,
            }
          : false,
        paymentPointer: paymentPointer?.trim()?.length > 0 ? paymentPointer?.trim() : undefined,
        devnetAddress: devnetAddress,
        uid: walletAddress.address,
        email: email,
        subscriptionFee: subscriptionFee > 0 ? subscriptionFee : undefined,
        currency: subscriptionFee > 0 ? currency : undefined,
        nftStorageAPIKey: nftStorageApiKey,
      };

      setProfile({
        podcasterId: podcasterId,
        sender: podcaster.uid,
        profile,
        instruction: `Updating profile for podcaster ${podcasterName} (${podcasterId})`,
      });
      setRequestUpdate(true);
      return;
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          message: "Unable to update podcaster. Please review your information",
        };
      });
      return;
    }
  };

  return (
    <>
      {requestUpdate && (
        <Modal isOpen={requestUpdate}>
          <QRCodeForm
            heading={`Updating ${podcasterName}'s Profile`}
            body={podcasterProfile}
            cancelQr={cancelUpload}
            onSuccess={onSuccess}
            onFail={onFailure}
            type={TRANSACTION_OPTIONS.UPDATE_PODCASTER_PROFILE}
          ></QRCodeForm>
        </Modal>
      )}
      <ContentWrapper heading={`${podcaster?.name} Settings`}>
        <NavContainer style={{marginBottom: "5%"}}>
          <Nav>
            <NavLink active={currentTab === 'general'} onClick={() => setTab('general')}>
              General
            </NavLink>
            <NavLink active={currentTab === 'gravatar'} onClick={() => setTab('gravatar')}>
              Gravatar
            </NavLink>
            <NavLink active={currentTab === 'monetization'} onClick={() => setTab('monetization')}>
              Payments & Monetization
            </NavLink>
          </Nav>
        </NavContainer>
        <PodcasterContentWrapper>
          {currentTab === 'general' && <FormWrapper>
            <PodcasterContentHeading>General Settings</PodcasterContentHeading>
            <FormWrapper>
            <FormLabel>Podcaster Name</FormLabel>
            <FormInput1
              name={PODCASTER_FORM_INPUT_TYPES.NAME}
              placeholder="Name"
              value={podcasterName}
              onChange={handleInput}
            />
           {errors?.name && <ErrorText>{errors.name}</ErrorText>}
           </FormWrapper>
         
          <FormWrapper>
            <FormLabel>Full Name</FormLabel>
            <FormInput1
              name={PODCASTER_FORM_INPUT_TYPES.FULL_NAME}
              placeholder="Full Name"
              value={fullName}
              onChange={handleInput}
            />
          </FormWrapper>
            <FormWrapper>
            <FormLabel>Management address</FormLabel>
            <FormInput1
              name={PODCASTER_FORM_INPUT_TYPES.ADDRESS}
              disabled={true}
              placeholder="Management address"
              value={walletAddress.address}
              onChange={handleInput}
            />
          </FormWrapper>
          </FormWrapper>}
          {currentTab === 'gravatar' && <FormWrapper>
            <PodcasterContentHeading>Gravatar Settings</PodcasterContentHeading>
          
          {profilePictureType === "custom" && (
            <FormWrapper>
              <label
                style={{ cursor: "pointer" }}
                onClick={() => {
                  toggleModal();
                  modalContent(
                    <OptionModal heading={"Profile Picture"}>
                      <HoverOption>
                        <label for={PODCASTER_FORM_INPUT_TYPES.PROFILE_PICTURE}>
                          Upload New Image
                        </label>
                      </HoverOption>
                      {profilePicture?.url && (
                        <HoverOption
                          onClick={() => {
                            setProfilePicture();
                            toggleModal();
                            modalContent();
                          }}
                        >
                          Delete
                        </HoverOption>
                      )}
                    </OptionModal>
                  );
                }}
              >
                {
                  <AlbumArt
                    isRounded={true}
                    size={"18rem"}
                    alt={podcasterName}
                    albumArt={
                      profilePicture?.url
                        ? profilePicture?.url
                        : DEFAULT_IMAGE(600)
                    }
                  />
                }
                <FormInput1
                  style={{ display: "none" }}
                  id={PODCASTER_FORM_INPUT_TYPES.PROFILE_PICTURE}
                  name={PODCASTER_FORM_INPUT_TYPES.PROFILE_PICTURE}
                  onChange={handleInput}
                  type={"file"}
                  accept="image/x-png,image/jpeg, image/jpg"
                  multiple="false"
                />
              </label>
              {profilePicture?.progress > 0 && !profilePicture?.url && (
            <>
              <PushSpinner loading={profilePicture?.url ? false : true} />
              {profilePicture?.progress < 100
                ? `${profilePicture?.progress.toFixed(2)}%`
                : `Processing`}
            </>
          )}
            </FormWrapper>
          )}
          <GravatarSettings type={'Podcaster'} hasGravatarProfile={hasGravatarProfile} email={email} options={{
            profilePictureType,
            switchProfilePictureType,
            headerType,
            switchHeaderType,
            bioType,
            bio,
            bioInputName: PODCASTER_FORM_INPUT_TYPES.BIO,
            switchBioType,
            handleInput,
            tipping,
            setTipping,
            showGallery,
            setShowGallery,
            showTrips,
            setTrips,
            about,
            toggleTipping
          }}/>
          </FormWrapper>}
          {currentTab === 'monetization' && <FormWrapper>
          <PodcasterContentHeading>Payments & Monetization</PodcasterContentHeading>

          <FormWrapper>
          <PodcasterSettingsWrapper>
            <>
              <FormLabel>
                <>
                  Micropayments Subscription{" "}
                  <ToolTip
                    style={{ marginLeft: "2%" }}
                    text={
                      "Receive micropayments for music streams directly to your XRP wallet address."
                    }
                  >
                    <FaInfo
                      color={themeColors?.secondaryBg}
                      style={{
                        marginLeft: "1%",
                        border: "1px solid",
                        borderRadius: "50%",
                      }}
                    />
                  </ToolTip>
                </>
              </FormLabel>
              {!paymentChannel?.channel && (
                <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    enableMonetization();
                  }}
                >
                  Enable
                </ActionButton>
              )}
              {paymentChannel?.channel && (
                <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => disableFunds()}
                >
                  Disable
                </ActionButton>
              )}
            </>
          </PodcasterSettingsWrapper>
          {paymentChannel?.channel && funds / 1000000 > 0 && (
            <><PodcasterSettingsWrapper style={{justifyContent: 'space-between'}}>
              <CurrencySelector setCurrency={setFundsCurrency} currency={fundsCurrency}/>
              <FormText>
                Available Funds:{" "}
                <div style={{ marginLeft: "2%" }}>
                  {fundsCurrency !== "XRP" && !isRateLoaded && 'Loading Balance...'}
                  {fundsCurrency !== "XRP" && isRateLoaded && parseFloat((funds / 1000000) * curencyRate).toFixed(6)}
                  {fundsCurrency === "XRP" && parseFloat(funds / 1000000).toFixed(6)} {fundsCurrency}
                </div>
              </FormText>

              
            </PodcasterSettingsWrapper>
            <PodcasterSettingsWrapper>
            {(funds / 1000000) < 1 && <CustomButton onClick={() => {getFunds()}}><small>Refresh Funds <MdRefresh color="white" /></small></CustomButton>}
            <ActionButtonsContainer>
            {(funds / 1000000) >= 1 && (
              <><CustomButton onClick={() => {getFunds()}}>Refresh Funds <MdRefresh color="white" /></CustomButton>
                <CustomButton1
                  onClick={() => {
                    claimFunds();
                  }}
                >
                  Claim Funds
                </CustomButton1></>
              )}</ActionButtonsContainer>
            </PodcasterSettingsWrapper></>
          )}
          </FormWrapper>
          <FormWrapper><>{podcaster?.isVerified && (
            
            <FormWrapper>
              
                <FormLabel>Monthly Subscription Fee <CurrencySelector setCurrency={setCurrency} currency={currency} /></FormLabel>
              
              <FormInput1
                name={PODCASTER_FORM_INPUT_TYPES.SUBSCRIPTION_FEE}
                type={"number"}
                min={0}
                placeholder="Subscription Fee"
                value={subscriptionFee}
                onChange={handleInput}
              />
            </FormWrapper>
          )}
          
            <FormWrapper>
            <FormLabel>Payment Pointer (ILP)</FormLabel>
            <FormInput1
              name={PODCASTER_FORM_INPUT_TYPES.PAYMENT_POINTER}
              placeholder="Payment Pointer"
              value={paymentPointer}
              onChange={handleInput}
            /></FormWrapper>
          </></FormWrapper>
          
       </FormWrapper>}
        </PodcasterContentWrapper>
        <PodcasterContentWrapper>
          {errors?.message && <ErrorText>{errors.message}</ErrorText>}
          <ActionButtonsContainer>
            <Button1 style={{marginRight: "2%"}} onClick={() => {
               navigate(-1)
            }}>Cancel</Button1>
            <Button1 style={{marginRight: "2%"}} onClick={updateProfile}>Update Profile</Button1>
          </ActionButtonsContainer>
        </PodcasterContentWrapper>
      </ContentWrapper>
    </>
  );
}

export default PodcasterSettings