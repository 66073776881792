import React from 'react'
import MusicMarketingVideoGenerator from '../../components/MusicMarketingVideoGenerator'
import { useParams } from 'react-router-dom'

const PromoteMusicPage = () => {
    const {contentId, contentType } = useParams()
  return (
    <>
    <MusicMarketingVideoGenerator 
    type={contentType === 'regium' ? 'royalty share' : contentType} 
    id={contentId}/>
    </>
  )
}

export default PromoteMusicPage