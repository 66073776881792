import React, { useState, useRef, useEffect } from 'react';
import useMusic from '../../hooks/useMusic';
import { CONTENT_TYPES } from '../../utils/Enum';
import { useNavigate } from 'react-router-dom';
import useVideos from '../../hooks/useVideos';
import ContentWrapper from '../ContentWrapper';
//import MarketingContentGenerator from './MarketingContentGenerator';



const MusicMarketingVideoGenerator = ({type, id}) => {
      const navigate = useNavigate();
    const [content, setContent] = useState()
    const [isLoaded, setLoaded] = useState(false)
    const {
        getAlbum,
        getPlaylist,
        getSong,
        getEpisode,
        getMusicRoyaltyInfo,
        getNFTokenCollection,
      } = useMusic();
      const {
        getVideo,
      } = useVideos()

    useEffect(() => {
       setLoaded(false);
          switch (type) {
            case "album":
              getAlbum(id)
                .then((data) => {
                  if (data && !data.error) {
                    /*                     //console.log('album ',data)
                     */ setContent(data);
                    setLoaded(true);
                    
                    return 
                  } else {
                    navigate(-1);
                  }
                })
                .catch((err) => {
                  navigate(-1);
                  console.error(err);
                });
              break;
           
            case "collection":
              getNFTokenCollection(id)
                .then((data) => {
                  if (data && !data.error) {
                    //console.log('collection ',data)
                    setContent(data);
                    setLoaded(true);
                  } else {
                    navigate(-1);
                  }
                })
                .catch((err) => {
                  navigate(-1);
                  console.error(err);
                });
              break;
            case "song":
              getSong(id)
                .then((data) => {
                  if (data && !data?.error) {
                    //console.log('song ',data)
                    setContent(data);
                    setLoaded(true);
                    
                    return 
                  } else {
                    navigate(-1);
                  }
                })
                .catch((err) => {
                  navigate(-1);
                  console.error(err);
                });
              break;
            case "episode":
              getEpisode(id)
                .then((data) => {
                  if (data && !data.error) {
                    setContent(data);
                    setLoaded(true);
                    
                  } else {
                    navigate(-1);
                  }
                })
                .catch((err) => {
                  navigate(-1);
                  console.error(err);
                });
              break;
              case "video":
                getVideo(id)
                  .then((data) => {
                    if (data && !data.error) {
                      /*                     //console.log('album ',data)
                       */ setContent(data);
                      setLoaded(true);
                      
                      return 
                    } else {
                      navigate(-1);
                    }
                  })
                  .catch((err) => {
                    navigate(-1);
                    console.error(err);
                  });
                break;
            case CONTENT_TYPES.ROYALTY_SHARE : 
            getMusicRoyaltyInfo(id)
            .then((data) => {
              if (data && !data.error) {
                setContent(data);
                setLoaded(true);
                
                return 
              } else {
                navigate(-1);
              }
            })
            .catch((err) => {
              navigate(-1);
              console.error(err);
            });
            break
              default:
                navigate(-1);
              break;
          }
          // //console.log(id + ' ' + type)
          return () => {};
    }, [id, type])
    

    return(
        <>
        <ContentWrapper heading={'Reach Your Audience'}>
                {/* <MarketingContentGenerator type={type} content={content} isLoaded={isLoaded}/> */}
        </ContentWrapper>
        </>
    )
   
}

export default MusicMarketingVideoGenerator;