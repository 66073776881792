import { db } from "../../components/Authorization/firebase";
import {
  query,
  doc,
  getDoc,
  collection,
  getDocs,
  where,
  limit,
  orderBy,
  updateDoc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import axiosInstance from "../../components/Authorization/client";
import { getObjectLength } from "..";


  export const saveAlbumAsDraft = async (info) => {
    let albumArt = getObjectLength(info?.albumArt) > 0 ? Object.fromEntries(Object.entries(info?.albumArt).filter(([_, d]) => d !== null && d !== undefined && _ !== "file")) : undefined
    let draft
  try{

   draft = {
      currency: info?.currency,
      albumArt: albumArt,
      artists: info?.artists,
      songs: info?.songs?.length > 0 ? info?.songs?.map(s => {
        let song = Object.fromEntries(Object.entries(s).filter(([_, d]) => d !== null && d !== undefined))
        if(getObjectLength(song?.songUpload) > 0 && (song?.songUpload?.progress > 0 && song?.songUpload?.progress < 100)){
          song.songUpload.progress = 0
          delete song?.songUpload?.name
          delete song?.songUpload?.file
          
      }
      if(song?.songUpload?.file){
        delete song?.songUpload?.file
      }
      if(song?.songUpload?.progress === 100 && !song?.songUpload?.url){
        song.songUpload.progress = 0
      }
     if(getObjectLength(albumArt) > 0 ){
        song = {
          ...song,
          albumArt: albumArt
        }
      } 
        song = {
          ...song,
          videoFile: getObjectLength(song?.videoFile) > 0 && (song?.videoFile?.progress === 100 || song?.videoFile?.progress === 0) ? Object.fromEntries(Object.entries(song?.videoFile).filter(([_, d]) => d !== null && d !== undefined && _ !== "file")) : undefined,
          visualizerFile: getObjectLength(song?.visualizerFile) && (song?.visualizerFile?.progress === 100 || song?.visualizerFile?.progress === 0) > 0 ? Object.fromEntries(Object.entries(song?.visualizerFile).filter(([_, d]) => d !== null && d !== undefined && _ !== "file")) : undefined,

        }
        if(!song?.videoFile){
          delete song?.videoFile
        }
        if(!song?.visualizerFile){
          delete song?.visualizerFile
        }
        //console.log(song)
        return {
          ...song
        }
      
      
    }) : [],
      duration: info?.duration,
      userArtist: info?.userArtist,
      isDownloadable: info?.isDownloadable,
      isStreamable: info?.isStreamable,
      isMintable: info?.isMintable,
      isPreRelease: info?.isPreRelease,
      releaseDate: info?.releaseDate,
      mainGenre: info?.mainGenre,
      type: "album",
      isSubscription: info?.isSubscription,
      visualizerFile: getObjectLength(info?.visualizerFile) > 0 && (info?.visualizerFile?.progress === 100 || info?.visualizerFile?.progress === 0) ? Object.fromEntries(Object.entries(info?.visualizerFile).filter(([_, d]) => d !== null && d !== undefined && _ !== "file")) : undefined,
      albumName: info?.albumName,
      price: info?.price,
      license: info?.license,
      uploadedBy: info?.uploadedBy,
    };
    draft = Object.fromEntries(Object.entries(draft).filter(([_, d]) => d !== null && d !== undefined))
    //console.log(draft)
    if (info?.albumId?.length === 0 || !info?.albumId) {
      let albumId = await (await getDoc(doc(collection(db,"albums")))).id; //create draft id
      return setDoc(doc(db,`drafts/${albumId}`), draft)
        .then((doc) => {
          //console.log(doc);
          return albumId
        })
        .catch((err) => {
         console.error(err);
          return { error: "something went wrong" };
        });
    } else {
      return setDoc(doc(db,`drafts/${info?.albumId}`),draft)
        .then((doc) => {
          //console.log(doc);
          return info?.albumId
        })
        .catch((err) => {
          return axiosInstance
      .post(`/api/album/saveDraft`, {
        ...info,
      })
      .then((album) => {
        return album.data?.message ? album.data.message : false;
      })
      .catch((err) => {
        console.error(err);
        return {error: err};
      });
        });
    }}catch(err){
      console.err(err)
      return axiosInstance
      .post(`/api/album/saveDraft`, {
        ...info,
      })
      .then((album) => {
        return album.data?.message ? album.data.message : false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
      
    }
  };

  export const saveSongAsDraft = async (info) => {
    let songId = await (await getDoc(doc(collection(db,"songs")))).id; //create draft id
    let draft 
    try{

  
    draft = {
      songName: info?.songName,
      price: info?.price,
      isrc: info?.isrc,
      artists: info?.artists,
      autoIsrcCode: info?.autoIsrcCode,
      isrcType: info?.isrcType,
      userArtist: info?.userArtist,
      featuredArtists: info?.featuredArtists,
      songCredits: info?.songCredits,
      mainGenre: info?.mainGenre,
      subGenres: info?.subGenres,
      isStreamable: info?.isStreamable,
      isPreRelease: info?.isPreRelease,
      isDownloadable: info?.isDownloadable,
      isSubscription: info?.isSubscription,
      isCover: info?.isCover,
      type: "song",
      isMintable: info?.isMintable,
      explicit: info?.explicit,
      license: info?.license,
      currency: info?.currency,
      duration: info?.duration,
      payoutArtists: info?.payoutArtists,
      songUpload: (info?.songUpload?.progress > 0 && info?.songUpload?.progress < 100 ) || (info?.songUpload?.progress === 100 && !info?.songUpload?.url) ? {
        ...info?.songUpload,
        file: "",
        progress: 0,
      } : {...info?.songUpload,
        file: ""
      },
      albumArt: getObjectLength(info?.albumArt) > 0 && (info?.albumArt?.progress === 100 || info?.albumArt?.progress === 0)? Object.fromEntries(Object.entries(info?.albumArt).filter(([_, d]) => d !== null && d !== undefined && _ !== "file")) : undefined,
      videoFile: getObjectLength(info?.videoFile) > 0 && (info?.videoFile?.progress === 100 || info?.videoFile?.progress === 0)? Object.fromEntries(Object.entries(info?.videoFile).filter(([_, d]) => d !== null && d !== undefined && _ !== "file")) : undefined,
      visualizerFile: getObjectLength(info?.visualizerFile) > 0 && (info?.visualizerFile?.progress === 100 || info?.visualizerFile?.progress === 0) ? Object.fromEntries(Object.entries(info?.visualizerFile).filter(([_, d]) => d !== null && d !== undefined && _ !== "file")) : undefined,
      about: info?.about,
      uploadedBy: info?.uploadedBy,
      lyrics: info?.lyrics,
      releaseDate: info?.releaseDate,
      createdAt: new Date(),
    };
    draft = Object.fromEntries(Object.entries(draft).filter(([_, d]) => d !== null && d !== undefined))
   
    if (!info?.songId || info?.songId?.length === 0) {
      return await setDoc(doc(db,`drafts/${songId}`), draft).then((doc) => {
          //console.log(doc);
          return songId 
        })
        .catch((err) => {
          console.error(err);
          return { error: "something went wrong" }
        });
    } else {
      return setDoc(doc(db,`drafts/${info?.songId}`),draft)
        .then((doc) => {
          //console.log(doc);
          return info?.songId
        })
        .catch((err) => {
          console.error(err);
          console.log(draft)
          return { error: "something went wrong" }
        });
    }  
  }catch(err){
      console.error(err)
      return false
    }
  };

  export const saveVideoAsDraft = async (info) => {
    let videoId = await (await getDoc(doc(collection(db,"videos")))).id; //create draft id
    let draft 
    try{

  
    draft = {
      title: info?.title,
      price: info?.price,
      isrc: info?.isrc,
      artists: info?.artists,
      autoIsrcCode: info?.autoIsrcCode,
      isrcType: info?.isrcType,
      userArtist: info?.userArtist,
      featuredArtists: info?.featuredArtists,
      credits: info?.credits,
      mainGenre: info?.mainGenre,
      type: "video",
      //isMintable: info?.isMintable,
      explicit: info?.explicit,
      license: info?.license,
      currency: info?.currency,
      duration: info?.duration,
      payoutArtists: info?.payoutArtists,
      videoUpload: info?.videoUpload?.progress > 0 && info?.videoUpload?.progress < 100 ? {
        ...info?.videoUpload,
        file: "",
        progress: 0,
      } : {...info?.videoUpload,
        file: ""
      },
      song: info?.song,
      thumbnail: getObjectLength(info?.thumbnail) > 0 && (info?.thumbnail?.progress === 100 || info?.thumbnail?.progress === 0)? Object.fromEntries(Object.entries(info?.thumbnail).filter(([_, d]) => d !== null && d !== undefined && _ !== "file")) : undefined,
      about: info?.about,
      uploadedBy: info?.uploadedBy,
      releaseDate: info?.releaseDate,
      createdAt: new Date(),
    };
    draft = Object.fromEntries(Object.entries(draft).filter(([_, d]) => d !== null && d !== undefined))
   
    if (!info?.videoId || info?.videoId?.length === 0) {
      return await setDoc(doc(db,`drafts/${videoId}`), draft).then((doc) => {
          //console.log(doc);
          return videoId 
        })
        .catch((err) => {
          console.error(err);
          return { error: "something went wrong" }
        });
    } else {
      return setDoc(doc(db,`drafts/${info?.videoId}`),draft)
        .then((doc) => {
          //console.log(doc);
          return info?.videoId
        })
        .catch((err) => {
          console.error(err);
          console.log(draft)
          return { error: "something went wrong" }
        });
    }  
  }catch(err){
      console.error(err)
      return false
    }
  };

  export const saveEpisodeAsDraft = async (info) => {
    let draft = {
      podcasters: info?.podcasters,
      thumbnailRef: info?.thumbnailRef,
      thumbnail: getObjectLength(info?.thumbnail) > 0 && (info?.thumbnail?.progress === 100 || info?.thumbnail?.progress === 0)? Object.fromEntries(Object.entries(info?.thumbnail).filter(([_, d]) => d !== null && d !== undefined && _ !== "file")) : undefined,
      videoFile: getObjectLength(info?.videoFile) > 0 && (info?.videoFile?.progress === 100 || info?.videoFile?.progress === 0)? Object.fromEntries(Object.entries(info?.videoFile).filter(([_, d]) => d !== null && d !== undefined && _ !== "file")) : undefined,
      autoIsrcCode: info?.autoIsrcCode,
      isrcType: info?.isrcType,
      userPodcaster: info?.userPodcaster,
      episodeUpload: info?.episodeUpload?.progress > 0 && info?.episodeUpload?.progress < 100 ? {
        ...info?.episodeUpload,
        //url: info?.episodeUpload?.url,
        file: "",
        progress: 0,
      } : {
        ...info?.episodeUpload,
        file: "",
      } ,
      price: info?.price,
      isrc: info?.isrc?.trim(),
      genres: info?.genres,
      duration: info?.duration,
      isDownloadable: info?.isDownloadable,
      isSubscription: info?.isSubscription,
      isStreamable: info?.isStreamable,
      isMintable: info?.isMintable,
      isMusicContained: info?.isMusicContained,
      episodeName: info?.episodeName,
      description: info?.description,
      explicit: info?.explicit,
      currency: info?.currency,
      license: info?.license,
      about: info?.about,
      uploadedBy: info?.uploadedBy,
      releaseDate: info?.releaseDate,
      type: "episode",
      createdAt: new Date(),
      timestamps: info?.timestamps,
      songs: info?.songs,
      podcasterPayouts: info?.podcastersPayouts,
      artistPayouts: info?.artistPayouts
    };
    draft = Object.fromEntries(Object.entries(draft).filter(([_, d]) => d !== null && d !== undefined))
    if (info?.episodeId?.length === 0 || !info?.episodeId) {
      let episodeId = await (await getDoc(doc(collection(db,"episodes")))).id; //create draft id
      return setDoc(doc(db,`drafts/${episodeId}`), draft).then((doc) => {
          console.log(doc);
          return { message: episodeId } 
        })
        .catch((err) => {
          //console.error(err);
          return { error: "something went wrong" }
        });
    } else {
      return setDoc(doc(db,`drafts/${info?.episodeId}`), draft).then((doc) => {
          //console.log(doc);
          return { message: info?.episodeId }
        })
        .catch((err) => {
          //console.log(err);
          return { error: "something went wrong" }
        });
    }
  };

  export const getDraftsByUser = async (uid, setDrafts) => {
    return onSnapshot(query(collection(db, "drafts"), where("uploadedBy", "==", uid)), draftSnapshot => {
      if(!draftSnapshot.empty){
      setDrafts(draftSnapshot.docs.map(draft => {
        return {
          draftId : draft.id,
          ...draft.data()
        }
      }))}else{
        setDrafts([])
      }
      return
  })
  }

  export const getCollectionDraftsByUser = async (uid, setDrafts) => {
    return onSnapshot(query(collection(db, "drafts"), where("issuer", "==", uid)), draftSnapshot => {
      if(!draftSnapshot.empty){
      setDrafts(draftSnapshot.docs.map(draft => {
        return {
          draftId : draft.id,
          ...draft.data()
        }
      }))}else{
        setDrafts(false)
      }
      return
  })
  }