export default function hexToRgbA(hex){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        //console.log('rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.76)')
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.76)';
    }
    return false
}

export function hexToRgba(hex,a){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        //console.log('rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.76)')
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + a +')';
    }
    return false
}


export const imgToRgbA = (img) =>
{
    let imgobj = Image().open(img)
    let pixels = imgobj.convert('RGBA')
    let data = imgobj.getdata()
    let counts  //collections.Counter(data)
}

export function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

export function colorRandomizer(){
    return '#'+(Math.random()*0xFFFFFF<<0).toString(16)
}

export function lightOrDark(color) {
  const hex = color.replace('#', '');
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155 ? "light" : 'dark';
  }

export function lightOrDark1(color) {
  console.log(color)
  console.log(color.substring(1).match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/))
    let r, g, b, hsp
    // Check the format of the color, HEX or RGB?
  
    if (color.substring(1).match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)) {
  
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
  
      r = color[1];
      g = color[2];
      b = color[3];
    } 
    else {
  
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'
      )
               );
  
      r = color >> 16;
      g = color >> (8 & 255);
      b = color & 255;
    }
  
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );
  
    // Using the HSP value, determine whether the color is light or dark
    if (hsp>127.5) {
  
      return 'light';
    } 
    else {
  
      return 'dark';
    }
  }

export function extractColorPalette(imageSrc, numColors = 5, callback) {
    const img = new Image();
     // Important for images from other domains
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
  
      const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
      const colors = {};
  
      for (let i = 0; i < imageData.length; i += 4) {
        const r = imageData[i];
        const g = imageData[i + 1];
        const b = imageData[i + 2];
        const hex = `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
        colors[hex] = (colors[hex] || 0) + 1;
      }
  
      const sortedColors = Object.entries(colors)
        .sort(([, a], [, b]) => b - a)
        .slice(0, numColors)
        .map(([hex]) => hex);
  
      callback(sortedColors);
    };
  
    img.onerror = () => {
      console.error("Error loading image.");
      callback([]); // Return empty array on error
    };
  
    img.src = imageSrc;
  }

