import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import WelcomePage from "./pages/welcomePage";
import WebsiteThemeProvider from "./components/WebsiteTheme/WebsiteThemeContext";
import AlertProvider from "./components/Alert/AlertContext";
import SidebarNav from "./components/SidebarNav";
import { ModalProvider } from "./components/Modal/ModalContext";
import AlbumUploads from "./pages/musicUploads/albumUploads";
import SignIn from "./pages/accessPage/SignIn";
import SignUp from "./pages/accessPage/SignUp";
import SongUploads from "./pages/musicUploads/songUploads";
import EpisodeUploads from "./pages/musicUploads/episodeUploads";
import HomePage, {
  ArtistHomePage,
  LabelHomePage,
  PodcasterHomePage,
} from "./pages/homePage";
import LabelRegistrationPage from "./pages/accessPage/SignUp/LabelRegistrationPage";
import ArtistRegistrationPage from "./pages/accessPage/SignUp/ArtistRegistrationPage";
import PodcasterRegistrationPage from "./pages/accessPage/SignUp/PodcasterRegistrationPage";
import PageNotFound from "./pages/PageNotFound";
import Footer from "./components/Footer";
import Collection from "./pages/musicPages/Collection";
import Single from "./pages/musicPages/Single";
import NFToken from "./pages/musicPages/NFToken";
import RoyaltyShares from "./pages/musicPages/RoyaltyShares";
import Album from "./pages/musicPages/Album";
import Episode from "./pages/musicPages/Episode";
import ArtistProfile from "./pages/Profiles/artistProfile";
import RecordLabelProfile from "./pages/Profiles/recordLabelProfile";
import PodcasterProfile from "./pages/Profiles/podcasterProfile";
import NFTCollectionCreator from "./components/NFTCollectionCreator";
import MarketingCampaign from "./pages/marketing";
import EmailAccountSet from "./components/EmailAccountSet";
import LabelSettings from "./pages/Profiles/recordLabelProfile/recordLabelSettings";
import PodcasterProfileSettings from "./pages/Profiles/podcasterProfile/podcasterSettings";
import ArtistProfileSettings from "./pages/Profiles/artistProfile/artistSettings";
import DraftUploads from "./components/MusicUploadsForm/DraftUploads";
import SupportedTokens from "./components/SupportedTokens";
import ContactUsForm from "./components/ContactUsForm";
import { LogAuthorizationRoute } from "./components/Authorization";
import { Explore, Promote, Releases, Statistics } from "./pages/homePage/Pages";
import VideoUploads from "./pages/musicUploads/videoUploads";
import CommentBooth from "./components/CommentBooth";
import SetNFTokenMinter from "./components/SetNFTokenMinter";
import FrequentlyAskedQuestions from "./components/FrequentlyAskedQuestions";
import Video from "./pages/musicPages/Video";
import LabelRequesterPage from "./pages/labelRequesterPage";
import NFTokenWhitelister from "./components/NFTokenWhitelister";
import PromoteMusicPage from "./pages/marketing/PromoteMusicPage";

function App() {
  return (
    <Router>
      <AuthProvider>
        <WebsiteThemeProvider>
          <AlertProvider>
            <ModalProvider>
              <SidebarNav>
                <Routes>
                  <Route exact element={<SignIn />} path={"/signin"} />
                  <Route exact element={<SignUp />} path={"/signup"} />
                  <Route exact element={<WelcomePage />} path={"/"} />
                  <Route exact element={<Promote />} path={"/promote"} />
                  <Route exact element={<Explore />} path={"/explore"} />
                  <Route exact element={<Releases />} path={"/release"} />
                  <Route exact element={<Statistics />} path={"/stats"} />
                  <Route exact path="/createTL" element={<SupportedTokens />} />
                  <Route exact path="/contactUs" element={<ContactUsForm />} />

                  <Route
                    exact
                    path="/setEmailHash"
                    element={<EmailAccountSet />}
                  />

                  <Route
                    path="/faq"
                    exact
                    element={<FrequentlyAskedQuestions />}
                  />
                  <Route
                    exact
                    element={<LabelRegistrationPage />}
                    path={"/label/register"}
                  />
                  <Route
                    exact
                    element={<ArtistRegistrationPage />}
                    path={"/artist/register"}
                  />
                  <Route
                    exact
                    element={<PodcasterRegistrationPage />}
                    path={"/podcaster/register"}
                  />

                  <Route element={<LogAuthorizationRoute />}>
                    <Route exact element={<HomePage />} path={"/home"} />
                    <Route
                      exact
                      element={<ArtistHomePage />}
                      path={"/home/artist/:artistId"}
                    />
                    <Route
                      exact
                      element={<LabelHomePage />}
                      path={"/home/label/:labelId"}
                    />

                    <Route
                      exact
                      element={<PodcasterHomePage />}
                      path={"/home/podcaster/:podcasterId"}
                    />

                    <Route
                      exact
                      element={<ArtistProfileSettings />}
                      path={"/home/artist/:artistId/settings"}
                    />

                    <Route
                      exact
                      element={<PodcasterProfileSettings />}
                      path={"/home/podcaster/:podcasterId/settings"}
                    />

                    <Route
                      exact
                      element={<LabelSettings />}
                      path={"/home/label/:labelId/settings"}
                    />
                    <Route
                      exact
                      path="/setNFTMinter"
                      element={<SetNFTokenMinter />}
                    />
                    <Route
                      exact
                      element={<AlbumUploads />}
                      path={"/edit/album"}
                    />
                    <Route
                      exact
                      element={<SongUploads />}
                      path={"/edit/song"}
                    />
                    <Route
                      exact
                      element={<EpisodeUploads />}
                      path={"/edit/episode"}
                    />
                    <Route
                      exact
                      element={<NFTCollectionCreator />}
                      path={"/edit/collection"}
                    />
                    <Route
                      exact
                      path="/edit/collectionWhitelister"
                      element={<NFTokenWhitelister />}
                    />
                    <Route
                      exact
                      element={<VideoUploads />}
                      path={"/edit/video"}
                    />
                    <Route
                      exact
                      path="/edit/drafts"
                      element={<DraftUploads />}
                    />
                    <Route
                      exact
                      path={"/marketing"}
                      element={<MarketingCampaign />}
                    />
                    <Route
                      exact
                      path="/artist/:artist"
                      element={<ArtistProfile />}
                    />
                    <Route
                      exact
                      path="/label/:recordLabel"
                      element={<RecordLabelProfile />}
                    />
                    <Route
                      exact
                      path="/podcaster/:podcaster"
                      element={<PodcasterProfile />}
                    />
                    <Route exact path="/video/:videoId" element={<Video />} />
                    <Route exact path="/song/:songId" element={<Single />} />
                    <Route
                      exact
                      path="/collection/:collectionId"
                      element={<Collection />}
                    />
                    <Route
                      exact
                      path="/nftoken/:tokenId"
                      element={<NFToken />}
                    />
                    <Route exact path="/album/:albumId" element={<Album />} />
                    <Route
                      exact
                      path="/episode/:episodeId"
                      element={<Episode />}
                    />
                    <Route
                      exact
                      path="/regium/:royaltyId"
                      element={<RoyaltyShares />}
                    />
                    <Route
                      exact
                      path="/promote/:contentType/:contentId"
                      element={<PromoteMusicPage />}
                    />
                    <Route
                      exact
                      path="/requester/:type"
                      element={<LabelRequesterPage />}
                    />

                    <Route exact path="/comments" element={<CommentBooth />} />
                  </Route>
                  <Route element={<PageNotFound />} path={"*"} />
                </Routes>
                <Footer />
              </SidebarNav>
            </ModalProvider>
          </AlertProvider>
        </WebsiteThemeProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
